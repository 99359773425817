import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import betssonDonationBanner from '../assets/betssonDonationBanner.jpg';
import vivaLogo from '../assets/viva.png';
import isselLogo from '../assets/issel.png';
import betssonFoundationLogo from '../assets/betssonFoundation.png';

const useStyles = makeStyles({
    root: {
        flexDirection: 'column',
        background: 'white',
        minHeight: '100%',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    header: {
        justifyContent: 'center',
        backgroundColor: '#FB4401',
    },
    footer: {
        justifyContent: 'center',
        backgroundColor: '#d7dada',
        marginTop: 'auto',
    },
    mainContainer: {
        height: 'fit-content',
        background: '#1D1D1C',
    },
    donationTitle: {
        width: '100%',
        height: '25px',
    },
    mainContent: {
        background: 'white',
        height: 'calc(100% - 25px)',
        padding: '20px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rootContainer: {
        flexDirection: 'column',
    },
    info: {
        textAlign: 'justify',
        fontSize: '0.7em',
    },
    infoTitle: {
        fontSize: '1.5em',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    divider: {
        marginTop: '10px',
        background: '#FB4401',
        height: '2px',
    },
    inputContainer: {
        fontSize: '1.5em',
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    subContainer: {
        fontSize: '1.2em',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    donationButtonContainer: {
        marginTop: '35px',
    },
    donationButton: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: '15px',
        backgroundColor: '#FB4401',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#BC3301',
        }
    },
});

const Success = () => {
    const classes = useStyles();
    
    return (
        <Grid container className={classes.root} m={1}>
            <Grid container className={classes.header}>
                <Grid id="main" item>
                    <img src={betssonDonationBanner} alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                </Grid>
            </Grid>
            <Grid container className={classes.mainContainer}>
                <Grid item className={classes.donationTitle} />
                <Grid container className={classes.mainContent}>
                    <Grid id="main" container className={classes.rootContainer}>
                        <Grid item className={classes.infoTitle}>
                            Terms of Service
                        </Grid>
                        <Grid item className={classes.info} style={{ marginTop: "15px" }}>
                            The following information defines the conditions under which the use of the current application is operated by
                        </Grid>
                        <Grid item className={classes.info} style={{ marginTop: "15px" }}>
                            ISSEL (Intelligent Systems and Software Engineering Labgroup)
                        </Grid>
                        <Grid item className={classes.info}>
                            IPL (Information Processing Laboratory)
                        </Grid>
                        <Grid item className={classes.info}>
                            Department of Electrical and Computer Engineering
                        </Grid>
                        <Grid item className={classes.info}>
                            Aristotle University of Thessaloniki Campus
                        </Grid>
                        <Grid item className={classes.info}>
                            GR-54124, Thessaloniki
                        </Grid>
                        <Grid item className={classes.info}>
                            GREECE
                        </Grid>
                        <Grid item className={classes.info}>
                            (hereinafter “ISSEL”).
                        </Grid>
                        <Grid item className={classes.info} style={{ marginTop: "15px" }}>
                            Only these Terms of Services apply to any contract made between us in relation to services provided by ISSEL.
                            These conditions are accepted by the entirety of our community during the registration stage by checking the option
                            entitled “I accept the Terms of Services”. Any deviations from these specified conditions are not accepted.
                        </Grid>
                        <Grid item className={classes.info} style={{ marginTop: "15px", fontWeight: 'bold', fontSize: '1em' }}>
                            1. Description of the scope of service
                        </Grid>
                        <Grid item className={classes.info}>
                            ISSEL operates the current application, via which anyone can donate predefined or custom amounts of money to specific
                            humanitarian campaigns. This application is a direct collaboration between ISSEL, Betsson AB and Viva Wallet Group. 
                        </Grid>
                        <Grid item className={classes.info} style={{ marginTop: "15px", fontWeight: 'bold', fontSize: '1em' }}>
                            2. Access / Contract Confirmation
                        </Grid>
                        <Grid item className={classes.info}>
                            In order for users to access and use the application, they must first read the current Terms of Services.
                            Then, the user may proceed with donating an amount of money via the Viva Smart Checkout tool, only if they accept the written terms.
                        </Grid>
                        <Grid item className={classes.info} style={{ marginTop: "15px", fontWeight: 'bold', fontSize: '1em' }}>
                            3. Data Use, Data Sharing and Data Forwarding
                        </Grid>
                        <Grid item className={classes.info}>
                            ISSEL collects anonymous statistical data regarding the donations, which in no way can be used to infer the physical person that did the donation.
                            We may store such information in our infrastructure or such in databases owned and maintained by ISSEL’s affiliates, agents or service providers,
                            located in the European Union. ISSEL takes care to respect the legal data protection regulations, in particular the EU General Data Protection Regulation 2016/679.
                        </Grid>
                        <Grid item className={classes.info} style={{ marginTop: "15px", fontWeight: 'bold', fontSize: '1em' }}>
                            4. Liability of ISSEL
                        </Grid>
                        <Grid item className={classes.info}>
                            If ISSEL fails to comply with these terms and conditions, ISSEL is responsible for loss or damage the user suffers that is a foreseeable result of its
                            breach of these Terms and Conditions or its negligence, but ISSEL is not responsible for any loss or damage that is not foreseeable
                            (e.g., malicious cyber-attacks, including hacking). Furthermore, all donations are handled using the Viva Smart Checkout tool, and the donated money
                            is deposited in an account owned by Betsson AB. Therefore, a) ISSEL is not responsible for any loss or damage that occurs in the Viva Smart Checkout screens,
                            b) ISSEL  does not financially handle any transactions and c) Betsson AB is the sole responsible for the final utilization/handling of the donated amounts.
                        </Grid>
                        <Grid item className={classes.info} style={{ marginTop: "15px", fontWeight: 'bold', fontSize: '1em' }}>
                            5. Responsibilities and Obligations of the Registered User / Prohibited Practices / Exemption
                        </Grid>
                        <Grid item className={classes.info}>
                            The user is alone responsible for the use and content of this application and therefore for the information he/she provides about himself/herself.
                            The user should under no circumstances (prohibited conduct) give personal identifiers to third parties or share those with third parties. An inadmissible
                            commercial or business use in the sense of this provision is in particular the provision of goods or services in return for payment of any kind, the solicitation
                            of making an offer, or a reference to an attainable offer elsewhere. If the user breaches any of the obligations listed in this section 5, ISSEL can be entitled
                            to require compensation for any resulting damage or expenses. This does not apply if the user did not know he or she was committing a breach of section 5 and was
                            not reckless in the sense of not caring whether or not he or she was committing a breach of section 5.
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.footer} style={{ padding: '20px' }}>
                <Grid id="main" item style={{ width: "100%" }}>
                    <img src={vivaLogo} alt="Viva" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                    <img src={betssonFoundationLogo} alt="Betsson Foundation" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                    <img src={isselLogo} alt="Issel" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Success;
