import React from 'react';
import { Grid, Divider, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

import betssonDonationBanner from '../assets/betssonDonationBanner.jpg';
import okIcon from '../assets/ok.png';
import vivaLogo from '../assets/viva.png';
import isselLogo from '../assets/issel.png';
import betssonFoundationLogo from '../assets/betssonFoundation.png';
import greekFlag from '../assets/greekFlag.png';
import englishFlag from '../assets/englishFlag.png';

const useStyles = makeStyles({
    root: {
        flexDirection: 'column',
        background: 'white',
        minHeight: '100%',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    header: {
        justifyContent: 'center',
        backgroundColor: '#FB4401',
    },
    footer: {
        justifyContent: 'center',
        backgroundColor: '#d7dada',
        marginTop: 'auto',
    },
    mainContainer: {
        height: 'fit-content',
        background: '#1D1D1C',
    },
    donationTitle: {
        width: '100%',
        height: '25px',
    },
    mainContent: {
        background: 'white',
        height: 'calc(100% - 25px)',
        padding: '20px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rootContainer: {
        flexDirection: 'column',
        position: 'relative',
    },
    info: {
        marginTop: '1em',
        textAlign: 'center',
    },
    divider: {
        marginTop: '10px',
        background: '#FB4401',
        height: '2px',
    },
    inputContainer: {
        fontSize: '1.5em',
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    subContainer: {
        fontSize: '1.2em',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    donationButtonContainer: {
        marginTop: '35px',
    },
    donationButton: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: '15px',
        backgroundColor: '#FB4401',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#BC3301',
        }
    },
    languages: {
        position: 'absolute',
        top: '-15px',
        right: '0px',
    },
});

const lngs = {
	gr: greekFlag,
	en: englishFlag,
};

const Success = () => {
	const { t, i18n } = useTranslation();
    const classes = useStyles();
    
    return (
        <Grid container className={classes.root} m={1}>
            <Grid container className={classes.header}>
                <Grid id="main" item>
                    <img src={betssonDonationBanner} alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                </Grid>
            </Grid>
            <Grid container className={classes.mainContainer}>
                <Grid item className={classes.donationTitle} />
                <Grid container className={classes.mainContent}>
                    <Grid id="main" container className={classes.rootContainer}>
                        <Grid item className={classes.info} style={{ marginTop: '1.3em' }}>
                            {t("PaymentAmount.donationTitle1")}
                            <span style={{ fontWeight: 'bold' }}> Galatasaray Foundation</span>
                            {t("PaymentAmount.donationTitle2")}
                        </Grid>
                        <Grid item className={classes.info}>
                            {t("PaymentAmount.donationTitle3")}
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item className={classes.inputContainer}>
                            <img src={okIcon} alt="" style={{width: '60px', height: '100%', objectFit: 'contain'}} />
                            {t("Success.successMessage1")}
                        </Grid>
                        <Grid item className={classes.subContainer}>
                            {t("Success.successMessage2")}
                        </Grid>
                        <Grid item className={classes.donationButtonContainer}>
                            <Button 
                                variant="contained"
                                className={classes.donationButton}
                                fullWidth
                                href="/"
                            >
                                {t("Success.backToHome")}
                            </Button>
                        </Grid>
                        <Grid item className={classes.languages}>
                            {Object.keys(lngs).map((lng) => (
                                <Button
                                    key={lng}
                                    onClick={() => { i18n.changeLanguage(lng); }}
                                >
                                    <img src={lngs[lng]} alt={lng} style={{width: '100%', height: '100%', maxWidth: "30px", objectFit: 'contain'}} />
                                </Button>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.footer} style={{ padding: '20px' }}>
                <Grid id="main" item style={{ width: "100%" }}>
                    <img src={vivaLogo} alt="Viva" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                    <img src={betssonFoundationLogo} alt="Betsson Foundation" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                    <img src={isselLogo} alt="Issel" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Success;
