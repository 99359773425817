import React from 'react';
import {
    BrowserRouter as Router, Route, Switch, Redirect
} from 'react-router-dom';

import PaymentAmount from './PaymentAmount';
import Success from './Success';
import Failure from './Failure';
import ToS from './ToS';

import "../i18n.js";

const Routes = () => (
    <Router>
        <Switch>
            <Route exact path="/">
                <PaymentAmount />
            </Route>
            <Route exact path="/success">
                <Success />
            </Route>
            <Route exact path="/failure">
                <Failure />
            </Route>
            <Route exact path="/tos">
                <ToS />
            </Route>
            <Redirect from="*" to="/" />
        </Switch>
    </Router>
);

export default Routes;
