import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
        order: ["localStorage"],
        lookupLocalStorage: 'i18nextLng',
        caches: ['localStorage'],
		fallbackLng: "el",
		resources: {
			en: {
				translation: {
					PaymentAmount: {
						donationAmount: "Donation Amount:",
                        donationTitle1: "Betsson and AEK are moving forward together in financial aid to the ",
                        donationTitle2: ", which is actually done to provide accommodation to our fellow citizens who were affected by the great earthquake in Turkey.",
                        donationTitle3: "You can also contribute by showing your solidarity. Every amount you can contribute can make a difference!",
                        donate: "Donate",
                        tos1: "I agree to the",
                        tos2: "terms of use",
					},
                    Success: {
                        successMessage1: "Transaction completed successfully!",
                        successMessage2: "Thank you very much!",
                        backToHome: "Back to Home",
                    },
                    Failure: {
                        failureMessage1: "Hmm, something went wrong!",
                        failureMessage2: "Please try again",
                    },
				},
			},
			gr: {
				translation: {
					PaymentAmount: {
						donationAmount: "Ποσό Δωρεάς:",
                        donationTitle1: "Betsson και ΑΕΚ προχωρούν μαζί σε οικονομική ενίσχυση προς το ",
                        donationTitle2: ", η οποία ουσιαστικά γίνεται για να παρέχει καταλύματα στους συνανθρώπους μας που επλήγησαν από τον μεγάλο σεισμό της Τουρκίας.",
                        donationTitle3: "Μπορείς να συνεισφέρεις και εσύ σε αυτό το έργο δείχνοντας έμπρακτα την αλληλεγγύη σου. Κάθε ποσό που μπορείς να προσφέρεις είναι ικανό να κάνει τη διαφορά!",
                        donate: "Κάντε Δωρεά",
                        tos1: "Συμφωνώ με τους",
                        tos2: "όρους χρήσης",
					},
                    Success: {
                        successMessage1: "Η συναλλαγή ολοκληρώθηκε με επιτυχία!",
                        successMessage2: "Ευχαριστούμε πολύ!",
                        backToHome: "Πίσω στην Αρχική",
                    },
                    Failure: {
                        failureMessage1: "Χμμ, κάτι πήγε στραβά!",
                        failureMessage2: "Παρακαλώ δοκιμάστε ξανά",
                    },
				},
			},
		},
	});

export { default } from "i18next";
