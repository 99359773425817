import React from 'react';
import { Grid, Divider, Button, CircularProgress, Checkbox, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyInput from 'react-currency-input';
import ky from 'ky';
import { useTranslation } from "react-i18next";

import betssonDonationBanner from '../assets/betssonDonationBanner.jpg';
import vivaLogo from '../assets/viva.png';
import isselLogo from '../assets/issel.png';
import betssonFoundationLogo from '../assets/betssonFoundation.png';
import greekFlag from '../assets/greekFlag.png';
import englishFlag from '../assets/englishFlag.png';

const {
    REACT_APP_SERVER_URL,
    REACT_APP_SERVER_USERNAME,
    REACT_APP_SERVER_PASSWORD,
    REACT_APP_VIVA_URL,
} = process.env;

const useStyles = makeStyles({
    root: {
        flexDirection: 'column',
        background: 'white',
        minHeight: '100%',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    header: {
        justifyContent: 'center',
        backgroundColor: '#FB4401',
    },
    footer: {
        justifyContent: 'center',
        backgroundColor: '#d7dada',
        marginTop: 'auto',
    },
    mainContainer: {
        background: '#1D1D1C',
        height: 'fit-content',
    },
    donationTitle: {
        width: '100%',
        height: '25px',
    },
    mainContent: {
        background: 'white',
        height: 'calc(100% - 25px)',
        padding: '20px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rootContainer: {
        flexDirection: 'column',
        position: 'relative',
    },
    info: {
        marginTop: '1em',
        textAlign: 'center',
    },
    divider: {
        marginTop: '10px',
        background: '#FB4401',
        height: '2px',
    },
    title: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        marginTop: '10px',
    },
    inputContainer: {
        fontSize: '1.4em',
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
    inputValue: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        width: '60%',
        maxWidth: '250px',
        borderRadius: '12px',
        border: '2px solid #7296a7',
        textAlign: 'center',
        outline: 'none',
    },
    defaultAmountsContainer: {
        fontSize: '1em',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    defaultAmount: {
        fontSize: '1em',
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '15px',
        border: '2px solid #FB4401',
        '&:hover': {
            backgroundColor: '#f2f2f2',
        }
    },
    selectedAmount: {
        fontSize: '1em',
        color: 'white',
        backgroundColor: '#FB4401',
        borderRadius: '15px',
        border: '2px solid #FB4401',
        '&:hover': {
            backgroundColor: '#BC3301',
            border: '2px solid #BC3301',
        }
    },
    donationButtonContainer: {
        marginTop: '35px',
    },
    tosContainer: {
        marginTop: '15px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.8em',
    },
    donationButton: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: '15px',
        backgroundColor: '#FB4401',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#BC3301',
        }
    },
    spinnerContainer: {
        width: '100%', 
        height: '100%', 
        position: 'absolute',
        top: '0px',
        left: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255, 255, 255, 0.4)',
    },
    spinner: {
        width: '4em!important',
        height: '4em!important',
        color: '#FB4401',
    },
    languages: {
        position: 'absolute',
        top: '-15px',
        right: '0px',
    },
});

const kyInstance = ky.extend({
	timeout: false,
	prefixUrl: REACT_APP_SERVER_URL,
	retry: {
		statusCodes: [401, 408, 413, 429, 502, 503, 504],
		limit: 2,
		methods: ["get", "post", "put", "head", "delete", "options", "trace"],
	},
	hooks: {
		beforeRequest: [(request) => {
            const username = REACT_APP_SERVER_USERNAME;
            const password = REACT_APP_SERVER_PASSWORD;
            const authorizationHeader = Buffer.from(`${username}:${password}`).toString("base64");
			request.headers.set("authorization", `Basic ${authorizationHeader}`);
		}],
	},
});

const lngs = {
	gr: greekFlag,
	en: englishFlag,
};

const PaymentAmount = () => {
	const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [spinnerOpen, setSpinnerOpen] = React.useState(false);
    const [amount, setAmount] = React.useState(10);
    const [tosAccepted, setTosAccepted] = React.useState(false);

    const onAmountChange = (__event, maskedValue) => {
        setAmount(parseInt(maskedValue, 10));
    };

    const changePage = async () => {
        setSpinnerOpen(true);
        const searchParams = new URLSearchParams({ amount: amount * 100 });
        const { success, orderCode } = await kyInstance.get("api/viva/payment", { searchParams }).json();
        setSpinnerOpen(false);
        if (success) {
            window.location = `${REACT_APP_VIVA_URL}/web/checkout?ref=${orderCode}&color=EA5628`;
        }
    };

    return (
        <Grid container className={classes.root} m={1}>
            <Grid container className={classes.header}>
                <Grid id="main" item>
                    <img src={betssonDonationBanner} alt="" style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                </Grid>
            </Grid>
            <Grid container className={classes.mainContainer}>
                <Grid item className={classes.donationTitle} />
                <Grid container className={classes.mainContent}>
                    <Grid id="main" container className={classes.rootContainer}>
                        <Grid item className={classes.info} style={{ marginTop: '1.3em' }}>
                            {t("PaymentAmount.donationTitle1")}
                            <span style={{ fontWeight: 'bold' }}> Galatasaray Foundation</span>
                            {t("PaymentAmount.donationTitle2")}
                        </Grid>
                        <Grid item className={classes.info}>
                            {t("PaymentAmount.donationTitle3")}
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item className={classes.title}>
                            {t("PaymentAmount.donationAmount")}
                        </Grid>
                        <Grid item className={classes.inputContainer}>
                            <CurrencyInput 
                                className={classes.inputValue} 
                                value={amount} 
                                onChangeEvent={onAmountChange}
                                precision="0"
                                suffix=" €"
                                decimalSeparator=","
                                thousandSeparator="."
                                selectAllOnFocus
                                pattern="[0-9]*"
                                inputMode='numeric'
                            />
                        </Grid>
                        <Grid item className={classes.defaultAmountsContainer}>
                            <Button 
                                variant="contained"
                                className={(amount === 1) ? classes.selectedAmount : classes.defaultAmount}
                                onClick={() => { setAmount(1); }}
                            >
                                1€
                            </Button>
                            <Button 
                                variant="contained"
                                className={(amount === 10) ? classes.selectedAmount : classes.defaultAmount}
                                onClick={() => { setAmount(10); }}
                            >
                                10€
                            </Button>
                            <Button 
                                variant="contained"
                                className={(amount === 20) ? classes.selectedAmount : classes.defaultAmount}
                                onClick={() => { setAmount(20); }}
                            >
                                20€
                            </Button>
                            <Button 
                                variant="contained"
                                className={(amount === 50) ? classes.selectedAmount : classes.defaultAmount}
                                onClick={() => { setAmount(50); }}
                            >
                                50€
                            </Button>
                        </Grid>
                        <Grid item className={classes.donationButtonContainer}>
                            <Button 
                                disabled={!tosAccepted}
                                variant="contained"
                                className={classes.donationButton}
                                fullWidth
                                onClick={changePage}
                            >
                                {t("PaymentAmount.donate")}
                            </Button>
                        </Grid>
                        <Grid item className={classes.tosContainer}>
                            <Checkbox
                                color='primary'
                                checked={tosAccepted}
                                onChange={() => setTosAccepted(!tosAccepted)}
                            />
                            <Grid style={{ marginLeft: "5px" }}>
                                {t("PaymentAmount.tos1")}
                                <Link href="/tos" target="_blank" rel="noopener" style={{ color: "#EA5628", marginLeft: "2px" }}>
                                    {t("PaymentAmount.tos2")}
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.languages}>
                            {Object.keys(lngs).map((lng) => (
                                <Button
                                    key={lng}
                                    onClick={() => { i18n.changeLanguage(lng); }}
                                >
                                    <img src={lngs[lng]} alt={lng} style={{width: '100%', height: '100%', maxWidth: "30px", objectFit: 'contain'}} />
                                </Button>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.spinnerContainer} style={{display: (spinnerOpen) ? 'flex' : 'none'}}>
                        <CircularProgress className={classes.spinner} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.footer} style={{ padding: '20px' }}>
                <Grid id="main" item style={{ width: "100%" }}>
                    <img src={vivaLogo} alt="Viva" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                    <img src={betssonFoundationLogo} alt="Betsson Foundation" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                    <img src={isselLogo} alt="Issel" style={{width: '100%', height: '100%', objectFit: 'contain', maxHeight: '50px' }} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PaymentAmount;
